import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import * as textMask from 'text-mask-core/dist/textMaskCore.js';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMask]',
  standalone: true,

  host: {
    '(input)': 'handleInput()',
  },
})
export class MaskDirective implements AfterViewInit, OnDestroy {
  @Input() mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // dd/mm/yyyy
  @Input() initConform = false;

  maskedInputController;

  @HostListener('input')
  handleInput(): void {
    this.maskedInputController?.update();
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent): void {
    const clipboardData: DataTransfer = event.clipboardData;
    const pastedText: string = clipboardData.getData('text');
    if (this.maskedInputController && pastedText) {
      if (this.control) {
        this.control.control.patchValue('');
      }
      event.preventDefault();
      const maskedText = textMask.conformToMask(pastedText, this.mask, {}).conformedValue;
      const cursorPosition = this.element.nativeElement.selectionStart || 0;
      const currentValue = this.element.nativeElement.value;
      const newValue: string =
        currentValue.substring(0, cursorPosition) + maskedText + currentValue.substring(this.element.nativeElement.selectionEnd || 0);
      this.control.control.patchValue(newValue);
    }
  }

  constructor(
    private element: ElementRef,
    private control: NgControl,
  ) {}

  ngAfterViewInit(): void {
    this.maskedInputController = textMask.createTextMaskInputElement({
      inputElement: this.element.nativeElement,
      mask: this.mask,
    });
    if (this.control && this.initConform && this.control.value) {
      this.control.control.patchValue(textMask.conformToMask(this.element.nativeElement.value, this.mask, {}).conformedValue);
    }
    this.maskedInputController?.update();
    this.element.nativeElement.addEventListener('change', () => this.maskedInputController.update(), { passive: false });
  }

  ngOnDestroy(): void {
    // this.maskedInputController.destroy();
  }
}
