import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() isDisableScroll = false;
  @Input() title: string;
  @Input() showSlideIn = false;
  @Input() slideAnimateDue: number = null;
  @Input() uid: string = null;

  constructor(private router: Router) {}

  close(): void {
    if (this.slideAnimateDue) {
      this.showSlideIn = false;
      timer(this.slideAnimateDue)
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['/dashboard']);
        });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
