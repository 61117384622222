<div *ngIf="!next" class="choose-documents">
  <ng-container *ngIf="documents; else loading">
    <div class="choose-documents__header">
      <h3>Choose Documents you need</h3>
      <div class="search">
        <input [(ngModel)]="filter" placeholder="Search" type="text"/>
      </div>
      <p>Please select document:</p>
    </div>
    <ul class="choose-documents__list">
      <ng-container *ngIf="documents?.length; else noItems">
        <li *ngIf="!filter" class="choose-documents__list-item">
          <div class="form-group">
            <input (change)="selectAll()" [checked]="allSelected" id="doc-select-all" type="checkbox"/>
            <label for="doc-select-all">
              <span class="document-name">Select All</span>
            </label>
          </div>
        </li>
        <ng-container *ngFor="let doc of documents; let i = index">
          <li
            *ngIf="showDoc(doc.name)"
            [class.pointer-events-none]="(doc.key === DisabledDocKey.TestFolder && !selectedDocs.length) ||
                                         (doc.key === DisabledDocKey.PersonnelContractorRecordsChecklistV2 && !selectedDocs.length) ||
                                         doc.key === DisabledDocKey.EBC && !selectedDocs.length && !eSignature"
            class="choose-documents__list-item">
            <div class="form-group">
              <input
                (change)="toggle(i)"
                [attr.id]="'doc' + doc.key"
                [checked]="doc.checked"
                [disabled]="doc.key === DisabledDocKey.TestFolder && !selectedDocs.length ||
                            doc.key === DisabledDocKey.PersonnelContractorRecordsChecklistV2 && !selectedDocs.length ||
                            doc.isDisabled ||
                            doc.key === DisabledDocKey.EBC && !selectedDocs.length && !eSignature"
                type="checkbox"/>
              <label [attr.for]="'doc' + doc.key">
              <span [class.disabled]="doc.isDisabled" class="document-name">
                {{ doc.name }}
                <ng-container
                  *ngIf="doc.key === DisabledDocKey.TestFolder ||
                         doc.key === DisabledDocKey.PersonnelContractorRecordsChecklistV2||
                         doc.key === DisabledDocKey.EBC && !selectedDocs.length && !eSignature">
                  (sends only with other document)
                </ng-container>
              </span>
              </label>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <div *ngIf="documents?.length" class="next-btn-wrap">
      <button
        (click)="toggleStep()"
        [disabled]="!selectedDocs?.length"
        class="btn violet next-btn"
        data-e2e="choose-documents-proceed">
        Proceed
      </button>
    </div>
    <ng-template #noItems>
      <div class="message">No documents found</div>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="next" class="choose-documents-action">
  <div class="back-btn-wrapper">
    <span (click)="toggleStep()"><i class="icon-back-arrow"></i> Back to documents</span>
  </div>
  <ul class="choose-documents-action__list">
    <li *ngFor="let doc of selectedDocs">{{ doc.name }}</li>
  </ul>
  <div class="choose-documents-action__buttons">
    <h3>Send for sign by:</h3>
    <ul class="choose-documents-action__buttons-list">
      <li>
        <button
          (click)="activePhoneField = false; chooseAction('email')"
          *ngIf=!idMedical
          [disabled]="!actions?.email || isClickSend">
          <i class="icon-mail"></i> {{ checkEBC === 'onlyEBC' ? 'Send to  patient(s)' : 'Email' }}
        </button>
        <button
          (click)="activePhoneField = false; activeEmailField = true"
          *ngIf=idMedical
          [disabled]="!actions?.email || isClickSend">
          <i class="icon-mail"></i> Email
        </button>
      </li>
      <li *ngIf="checkEBC !== 'onlyEBC'">
        <button
          (click)="activePhoneField = true; errors = []; activeEmailField = false"
          [class.active]="activePhoneField"
          [disabled]="!actions?.text"
          class="text">
          <i class="icon-file-text1"></i> Text
        </button>
      </li>
      <li *ngIf="(hasPermissionPrint() | async) && !idMedical && checkEBC !== 'onlyEBC'">
        <button
          (click)="activePhoneField = false; activeEmailField = false; chooseAction('print')"
          [disabled]="!actions?.print || isClickSend">
          <i class="icon-print"></i> Print
        </button>
      </li>
      <li *ngIf="(hasPermissionPrint() | async) && checkEBC !== 'onlyEBC'">
        <button
          (click)="activePhoneField = false; activeEmailField = false; chooseAction('sign')"
          [disabled]="!actions?.sign || isClickSend">
          <i class="icon-signature"></i> Sign
        </button>
      </li>
    </ul>
  </div>
  <div *ngIf="errors.length" class="error-list">
    <div *ngFor="let err of errors" class="error">{{ err }}</div>
  </div>
  <div *ngIf="activePhoneField" class="phone-form">
    <div *ngIf="!phoneMeta.success" class="phone-form__wrapper">
      <label class="input-wrapper">
        <input
          [(ngModel)]="phone"
          [class.error]="phoneMeta.error"
          [mask]="phoneMask"
          appMask
          placeholder="+1 (---) --- - ----"
          type="tel"/>
        <span *ngIf="phoneMeta.error" class="err-msg">{{ phoneMeta.error }}</span>
      </label>
      <div class="button-wrapper">
        <button
          (click)="chooseAction('text')"
          [disabled]="isClickSend || (phoneValue?.length <= 2)"
          class="btn violet send-text">
          Send
        </button>
      </div>
    </div>
    <div *ngIf="phoneMeta.success" class="phone-form__success"><span class="icon"></span> Link successfully sent</div>
  </div>

  <div *ngIf="activeEmailField" class="phone-form">
    <div class="phone-form__wrapper">
      <label class="input-wrapper">
        <input (ngModelChange)="removeError('email')"
               [(ngModel)]="emailSend"
               [class.error]="errorSend.email"
               type="email"/>
        <span *ngIf="errorSend?.email" class="err-msg">{{ errorSend.email }}</span>
      </label>
      <div class="button-wrapper">
        <button
          (click)="chooseAction('email')"
          [disabled]="(!emailSend.length || isDisableSendAction)"
          class="btn violet send">
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<div class="success" style="display: none">
  <div class="success__wrapper">
    <div class="success__header">
      <span class="icon"></span>
    </div>
    <div class="success__body">
      <span>Link successfully sent</span>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="overlay">
    <img alt="loading" src="/assets/img/datatable/loading.gif"/>
  </div>
</ng-template>
