import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { UserModel } from '../shared/models';
import { getDataFromLocalStorage } from '../shared/helpers/other';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user: UserModel = getDataFromLocalStorage('user');
    const currentUser: UserModel = this.store.selectSnapshot(({ app }) => app.currentUser);
    if (currentUser || user) {
      return true;
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          redirectUrl: state.url,
        },
      };
      this.router.navigate(['/login'], navigationExtras);
    }
  }
}
