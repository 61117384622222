<div class="status">
  <ul class="status__list">
    <li [ngClass]="{ 'active': step === 1, 'done': step > 1 }" class="status__item">
      <div class="icon">
        <span class="step-num">1</span>
        <span class="step-done"><i class="icon-check"></i></span>
      </div>
      <span class="name">Choose department</span>
    </li>
    <li [ngClass]="{ 'active': step === 2, 'done': step > 2 }" class="status__item">
      <div class="icon">
        <span class="step-num">2</span>
        <span class="step-done"><i class="icon-check"></i></span>
      </div>
      <span class="name">Fill out the fields</span>
    </li>
    <li [class.active]="step === 3" class="status__item">
      <div class="icon">
        <span class="step-num">3</span>
        <span class="step-done"><i class="icon-check"></i></span>
      </div>
      <span class="name">Account Created</span>
    </li>
  </ul>
</div>

<div *ngIf="step === 1" class="choose-department">
  <div class="choose-department__header">Choose Department</div>
  <div class="choose-department__body">
    <div class="choose-department__body-items">
      <div *ngFor="let department of (departments$ | async)" class="btn-item">
        <input
          (change)="setDepartment(department.id)"
          [attr.id]="'department-' + department.id"
          [checked]="employeeForm.controls['departmentId'].value === department.id"
          name="radio-group"
          type="radio"/>
        <label [attr.for]="'department-' + department.id">{{ department.name }}</label>
      </div>
    </div>
    <button (click)="nextPage()" [disabled]="employeeForm.get('departmentId').invalid" class="next-btn">
      Next <i class="icon-back-arrow"></i>
    </button>
  </div>
</div>

<div *ngIf="step === 2" class="ga-form">
  <div [formGroup]="employeeForm" class="ga-form__wrapper" data-e2e-formgroup="ssoEmployeeForm">
    <button (click)="prevPage()" class="ga-form__back"><i class="icon-back-arrow"></i> Back to departments</button>
    <div class="ga-form__title">Create New Account</div>
    <div class="ga-form__form">
      <label class="select">
        <span>Select email or phone</span>
        <select (change)="resetFormFields(['email', 'phone'])" formControlName="selectId">
          <option [value]="null" disabled selected>Select email or phone</option>
          <option [value]="SelectIdEnum.Email">Email</option>
          <option [value]="SelectIdEnum.Phone">Phone</option>
        </select>
      </label>
      <label *ngIf="employeeForm.controls['selectId'].value === SelectIdEnum.Email" [class.has-error]="errors.email">
        <span>Email</span>
        <input
          autocomplete="off"
          placeholder="Enter email"
          type="text"
          formControlName="email"/>
        <span *ngIf="errors.email" class="error">{{ errors.email }}</span>
      </label>
      <label *ngIf="employeeForm.controls['selectId'].value === SelectIdEnum.Phone" [class.has-error]="errors.phone">
        <span>Phone</span>
        <input
          [textMask]="{ mask: phoneMask }"
          type="tel"
          placeholder="+1 (---) --- - ----"
          formControlName="phone"
          autocomplete="off"/>
        <span *ngIf="errors.phone" class="error">{{ errors.phone }}</span>
      </label>
      <label [class.has-error]="errors.password">
        <span>Password</span>
        <input placeholder="Enter password" type="password" formControlName="password"/>
        <span *ngIf="errors.password" class="error">{{ errors.password }}</span>
      </label>
      <label [class.has-error]="errors.firstName">
        <span>First Name</span>
        <input placeholder="Enter first name" type="text" formControlName="firstName"/>
        <span *ngIf="errors.firstName" class="error">{{ errors.firstName }}</span>
      </label>
      <label [class.has-error]="errors.lastName">
        <span>Last Name</span>
        <input placeholder="Enter last name" type="text" formControlName="lastName"/>
        <span *ngIf="errors.lastName" class="error">{{ errors.lastName }}</span>
      </label>
    </div>
    <div class="ga-form__next">
      <button (click)="generate()" class="next-btn">
        Next <i class="icon-back-arrow"></i>
      </button>
    </div>
  </div>
</div>
