import * as moment from 'moment';

function convertDateMDY(value, format: string = 'MM/DD/YYYY'): string {
  return value ? moment(value).format(format) : '';
}

function convertMomentDate(value: string, format: string = 'YYYY-MM-DD'): string {
  const date: string = value && typeof value === 'string' ? value?.replace(/_/g, '') : value;
  if (date && typeof date === 'string' && date?.length < 10) {
    return date;
  } else {
    return moment(date).isValid() ? moment(date).format(format) : '';
  }
}

export { convertDateMDY, convertMomentDate };
