import { Component, Output, EventEmitter, Input, ViewChild, OnInit, AfterViewInit, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { AngularSignaturePadModule, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { PointGroup } from 'signature_pad';

@Component({
  selector: 'app-signature-popup',
  templateUrl: './signature-popup.component.html',
  styleUrls: ['./signature-popup.component.scss'],
  standalone: true,
  imports: [AngularSignaturePadModule],
})
export class SignaturePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: { id: string; password: string; shouldFixedPage: true };
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('signature', { static: true }) public signaturePad: SignaturePadComponent;
  email = '';
  disableBtn = true;

  @HostListener('window:resize')
  onResize(): void {
    this.clear();
    this.initSignaturePad();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(): void {
    this.initSignaturePad();
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initSignaturePad();
    if (this.data?.shouldFixedPage) {
      this.fixedPage(true);
    }
  }

  ngOnDestroy(): void {
    this.fixedPage(false);
  }

  ngAfterViewInit(): void {
    this.disableBtn = this.signaturePad.isEmpty();
  }

  drawStart(): void {
    this.disableBtn = false;
  }

  save(): void {
    this.closed.emit(this.signaturePad.toDataURL('png'));
  }

  clear(): void {
    this.disableBtn = true;
    this.signaturePad.clear();
  }

  initSignaturePad(): void {
    const canvas: HTMLCanvasElement = document.querySelector('canvas');
    let value = null;
    if (window.innerWidth < 550) {
      canvas.setAttribute('width', `${window.innerWidth - 60}px`);
    }
    if (window.innerWidth > 550) {
      canvas.setAttribute('width', '500px');
    }
    value = window.innerHeight - 230 >= 400 ? 400 : window.innerHeight - 230;
    canvas.setAttribute('height', value < 70 ? '70px' : `${value}px`);
  }

  private fixedPage(enabled: boolean): void {
    const el: Element = document.querySelector('app-dashboard .dashboard');
    const mainWrapper: Element = document.querySelector('.main-wrapper');
    const body: HTMLBodyElement = document.querySelector('body');
    if (enabled) {
      if (el) {
        this.renderer.addClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.addClass(mainWrapper, 'fixed');
      }
      this.renderer.addClass(body, 'scrollDisabled');
    } else {
      if (el) {
        this.renderer.removeClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.removeClass(mainWrapper, 'fixed');
      }
      this.renderer.removeClass(body, 'scrollDisabled');
    }
  }

  public validateSign(): boolean {
    const arrayPointSign: PointGroup[] = this.signaturePad.toData();
    const len: number = arrayPointSign?.reduce((acc: number, value: PointGroup): number => {
      return value.points.length > 3 ? acc + value.points.length : acc;
    }, 0);
    return len > 20;
  }
}
