import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { PopupFactoryService } from './popups/popup-factory.service';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { AppState } from './store/app.state';
import { DatePipe } from '@angular/common';
import { ChooseDocumentsModule } from './popups/choose-documents/choose-documents.component';
import { UploadDocumentModule } from './popups/upload-document/upload-document.component';
import { ErrorMessageInterceptor } from './auth/interceptors/error-message.interceptor';
import { CalendarState } from './dashboard/store/calendar/calendar.state';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdmissionsCalendarState } from './dashboard/store/admissions-calendar/admissions-calendar.state';
import { ChooseDocumentsAdmissionModule } from './popups/choosed-documents-admission/choose-documents-admission.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TextMaskModule } from 'angular2-text-mask';
import { ComponentsModule } from './shared/components/components.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CustomErrorHandler } from './custom-error-handler';
import { OnlineStatusModule } from 'ngx-online-status';
import { ConnectionComponent } from './popups/connection/connection.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNoAnimationModule } from 'ngx-toastr';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true,
};
const FILTERS_KEY_SAVE: string[] = ['filters'];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    Ng2FlatpickrModule,
    OverlayModule,
    AuthModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    ChooseDocumentsModule,
    ChooseDocumentsAdmissionModule,
    UploadDocumentModule,
    PdfViewerModule,
    NgxsFormPluginModule.forRoot(),
    NgxsModule.forRoot([AppState, CalendarState, AdmissionsCalendarState], {
      executionStrategy: NoopNgxsExecutionStrategy,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'AAC',
      disabled: false,
    }),
    NgxsStoragePluginModule.forRoot({
      key: FILTERS_KEY_SAVE,
      storage: StorageOption.SessionStorage,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FullCalendarModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    Ng2FlatpickrModule,
    TextMaskModule,
    OverlayModule,
    ComponentsModule,
    OnlineStatusModule,
    ConnectionComponent,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastNoAnimationModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    DatePipe,
    PopupFactoryService,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    {
      provide: 'API_URL',
      useValue: environment.apiUrl,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useClass: CustomErrorHandler,
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
        ]
      : []),
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
