export enum AdmissionTypeEnum {
  Admission = 1,
  SupervisorReport = 2,
  DischargeSummary = 3,
  AdmissionInt = 4,
  SupervisorReportInt = 5,
  DischargeSummaryInt = 6,
  POC = 7,
  POCInt = 8,
}

export enum TrainingCompletedEnum {
  Person = 'person',
  Phone = 'phone',
  None = 'none',
}

export enum ActionPOCEnum {
  AdditionalCG = 'additional',
  ChangePOC = 'change',
  Both = 'both',
}

export enum CreateAdmissionPermissionEnum {
  FullPermission = 1,
  OnlyPOC = 2,
  WithoutPermission = 0,
}
