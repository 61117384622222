export interface List<T> {
  data: T;
  total: number;
}

export interface IdNameObject {
  id: number | string;
  name: string;
}

export interface IdTitleObject {
  id: number | string;
  title: string;
}

export interface UrlModel {
  url: string;
}

export interface LinkModel {
  link: string;
}

export interface ValidationFiled {
  errorMessage: string;
  violations: ErrorObject;
}

export interface PerPage {
  key: string;
  perPage: number;
}

export interface ErrorObject {
  [key: string]: string[];
}
