import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ClearEvents,
  CreateEvent,
  DeleteEvent,
  EditEvent,
  GetAdmissionAuthLink,
  GetEventById,
  GetEvents,
  GetAdmissionToken,
  GetAdmissionTokenSuccess,
  ModalEvent,
  SetEventSuccess,
  SetSelectedEvent,
  SetDateStart,
  TypeModalEvent,
} from './admissions-calendar.action';
import { finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { AdmissionsCalendarService } from '../../services/admissions-calendar.service';
import { Injectable } from '@angular/core';

export interface CalendarStateModel {
  events: Event[] | any;
  stateModal: TypeModalEvent;
  activeEventModal: Event;
  authLink: string;
  pageToken: string;
  selectedEvent: Event;
  patientId: string;
  role: string;
  dateStart: string;
  pending: boolean;
}

const defaultState: CalendarStateModel = {
  events: [],
  stateModal: TypeModalEvent.Close,
  activeEventModal: null,
  authLink: '',
  pageToken: '',
  selectedEvent: null,
  patientId: '',
  role: '',
  dateStart: '',
  pending: true,
};

@State({
  name: 'admissionsCalendarState',
  defaults: defaultState,
})
@Injectable()
export class AdmissionsCalendarState {
  constructor(
    private service: AdmissionsCalendarService,
    private store: Store,
  ) {}

  @Selector()
  static pending(state): string {
    return state.pending;
  }

  @Selector()
  static authLink(state): string {
    return state.authLink;
  }

  @Selector()
  static selectedEvent(state): string {
    return state.selectedEvent;
  }

  @Selector()
  static events(state): Event[] {
    return state.events;
  }

  @Selector()
  static pageToken(state): Event[] {
    return state.pageToken;
  }

  @Selector()
  static stateModal(state): Observable<TypeModalEvent> {
    return state.stateModal;
  }

  @Selector()
  static activeEventModal(state): Observable<TypeModalEvent> {
    return state.activeEventModal;
  }

  @Selector()
  static dateStart(state): Observable<TypeModalEvent> {
    return state.dateStart;
  }

  @Action(GetAdmissionAuthLink)
  getAdmissionAuthLink(ctx: StateContext<CalendarStateModel>): Observable<any> {
    this.setPending(ctx, true);
    return this.service.getCalendarAuthLink().pipe(
      tap((authLink: any) => {
        ctx.patchState({
          authLink: authLink.link,
        });
      }),
      finalize(() => {
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(GetEvents)
  getEvents(ctx: StateContext<CalendarStateModel>, { params, patientId }: GetEvents) {
    let allItems = [];
    const fetchPage = (p, id, pageToken = null) => {
      return this.service.getEvents({ ...p, pageToken }, id).pipe(
        tap(({ items }) => {
          const processedItems = items.map(item => {
            item.summary = item.summary === null ? 'No title' : item.summary;
            return item;
          });
          allItems = [...allItems, ...processedItems];
          ctx.patchState({
            events: [...allItems],
            patientId,
          });
        }),
        switchMap(({ pageToken: nextPageToken }) => {
          return nextPageToken ? fetchPage(p, id, nextPageToken) : EMPTY;
        }),
      );
    };

    this.setPending(ctx, true);

    return fetchPage(params, patientId).pipe(
      finalize(() => {
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(CreateEvent)
  createEvent(ctx: StateContext<CalendarStateModel>, { event, patientId, role }): Observable<any> {
    const state: CalendarStateModel = ctx.getState();
    return this.service.creatEvent(event, patientId, role).pipe(
      tap((newEvent: any) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: [...state.events, newEvent],
          selectedEvent: null,
        });
      }),
    );
  }

  @Action(GetEventById)
  getEventById(ctx: StateContext<CalendarStateModel>, { id, uid }: GetEventById): Observable<any> {
    return this.service.getEventById(id, uid);
  }

  @Action(EditEvent)
  editEvent(ctx: StateContext<CalendarStateModel>, { id, event, uid, role }): Observable<any> {
    const state: CalendarStateModel = ctx.getState();
    return this.service.updateEvent(id, event, uid, role).pipe(
      tap((newEvent: any) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: state.events.map(e => (e.id === id ? newEvent : e)),
          selectedEvent: null,
          dateStart: newEvent.start.dateTime,
        });
      }),
    );
  }

  @Action(DeleteEvent)
  deleteEvent(ctx: StateContext<CalendarStateModel>, { id, uid, role }: DeleteEvent): Observable<void> {
    this.setPending(ctx, true);
    const state: CalendarStateModel = ctx.getState();
    return this.service.deleteEvent(id, uid, role).pipe(
      tap(() =>
        ctx.patchState({
          events: state.events.filter(event => event.id !== id),
        }),
      ),
      finalize(() => {
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(ModalEvent)
  modalEvent(ctx: StateContext<CalendarStateModel>, { stateModal, id }: ModalEvent): void {
    const state: CalendarStateModel = ctx.getState();
    ctx.patchState({
      stateModal,
      activeEventModal: id ? state.events.find(event => event.id === id) : null,
    });
  }

  @Action(GetAdmissionToken)
  getToken(ctx: StateContext<CalendarStateModel>, code: string): Observable<void> {
    return this.service.getToken(code).pipe(finalize(() => this.store.dispatch(new GetAdmissionTokenSuccess())));
  }

  @Action(SetSelectedEvent)
  setSelectedEvent(ctx: StateContext<CalendarStateModel>, { event }: SetSelectedEvent): Observable<any> {
    ctx.patchState({ selectedEvent: null });
    return this.service.getEventById(event.id, event.ownerId).pipe(
      tap(value => {
        ctx.patchState({ selectedEvent: value });
      }),
    );
  }

  @Action(ClearEvents)
  clearEvents(ctx: StateContext<CalendarStateModel>): void {
    ctx.patchState({
      events: [],
    });
  }

  @Action(SetDateStart)
  SetDateStart(ctx: StateContext<CalendarStateModel>, { dateStart }: SetDateStart): void {
    ctx.patchState({ dateStart });
  }

  private setPending(ctx: StateContext<CalendarStateModel>, value: boolean) {
    ctx.patchState({
      pending: value,
    });
  }
}
