<div [ngClass]="popupBodyClass" [class.h100]="isHeightFull" [class.mw700]="isReadLatterConfirm" class="popup-body">
  <div *ngIf="title || !hideCloseBtn" class="pop-up-header">
    <div *ngIf="title" class="title">
      <span *ngIf="!hideIcon" class="check"><i class="icon-check1"></i></span> {{ title }}
    </div>
    <button *ngIf="!hideCloseBtn" (click)="onClose()" class="close" data-e2e="close-popup-btn"><i class="icon-close"></i></button>
  </div>
  <perfect-scrollbar [config]="config" class="scroll-container scroll">
    <div class="scroll">
      <div class="pop-up-content">
        <ng-template #popupContainer></ng-template>
      </div>
    </div>
  </perfect-scrollbar>
</div>
