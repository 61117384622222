export interface EmployeeList {
  uid: string;
  status: {
    id: number;
    title: string;
    color: string;
    isSpecial: boolean;
  };
  sales: string;
  createdAt: string;
  caregiverStatus?: string;
  startDate?: string;
  dob?: string;
  name?: {
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  skillCategory?: string[];
  notes?: { id: string; text: string };
  eSignature?: boolean;
  phone?: string;
  address?: {
    streetAddress?: string;
    apartment?: string;
    city?: string;
    state?: string;
    zip?: string;
    municipality?: string;
  };
  email?: string;
  socialSecurity?: string;
  maritalStatus?: string;
  timeAvailable?: string[];
  driverLicense?: string;
  languages?: string[];
  usaCitizenship?: boolean;
}
