<div [class.open]="showSlideIn" class="modal">
  <perfect-scrollbar [disabled]="isDisableScroll" class="scroll-container">
    <div class="header">
      <span class="title">{{ title }}</span>
      <a (click)="close()" class="close">
        <i class="icon-close"></i>
      </a>
    </div>

    <div class="main">
      <ng-content></ng-content>
    </div>
  </perfect-scrollbar>
</div>

<div (click)="close()" class="overlay-blur"></div>
