<div [class.disabled]="block" class="value-row">
  <input
    [style.margin-right.px]="value ? 6 : 0"
    (input)="change($event)"
    (keydown.enter)="textareaInput.blur()"
    [placeholder]="!value && placeholder ? placeholder : ''"
    [textMask]="{ mask: isNumber || addPhoneMask ? phoneMask : false }"
    [type]="isNumber ? 'tel' : 'text'"
    [disabled]="block"
    [readOnly]="block"
    #textareaInput
    contenteditable="true"
    tabindex="1"
    class="value"/>
  <i *ngIf="!block" class="icon-edit-header"></i>
</div>
