import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Payload } from '../../popups/schedule-visit/schedule-visit.component';
import { PopupFactoryService } from '../../popups/popup-factory.service';
import { MessagePopupComponent } from '../../popups/message-popup/message-popup.component';
import { Router } from '@angular/router';
import { AdmissionTypes } from '../store/admissions/admissions.state';
import { getDataFromLocalStorage, removeEmptyObjectValues } from '../../shared/helpers/other';
import { List } from '../../shared/models';
import { AdmissionListItem } from '../../shared/models/admission-list.models';
import { Admission } from '../../shared/models/admissions.models';
import { StatusAdmission } from '../../shared/models/reports.models';

@Injectable({
  providedIn: 'root',
})
export class AdmissionsService {
  url: string = `${this.apiUrl}/admissions`;

  constructor(
    private router: Router,
    private popup: PopupFactoryService,
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getList(params): Observable<List<AdmissionListItem>> {
    return this.http.get(this.url, { params, observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        return {
          data: res.body,
          total: +res.headers.get('X-Items-Count'),
        };
      }),
    );
  }

  getById(id: string | number, discharge: boolean = false): Observable<Admission> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user = getDataFromLocalStorage('user');
    const token = (user || {}).token || urlParams.get('jwt');
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url: string = discharge ? `${this.url}/${id}/discharge` : `${this.url}/${id}`;
    return this.http.get<Admission>(url, { headers });
  }

  getAdmissionInfoByToken(token: string): Observable<any> {
    return this.http.get(`${this.url}/patient/admission/${token}`).pipe(
      catchError((err: any) => {
        if (err) {
          this.popup
            .createPopup({
              popupComponent: MessagePopupComponent,
              preventBgClick: false,
              popupData: {
                message: 'Link is not valid as Admission has been already signed',
                closeRoute: null,
                yellowHeader: true,
              },
            })
            .pipe(take(1))
            .subscribe(() => {
              this.router.navigate(['/']);
            });
        }
        return throwError(() => err);
      }),
    );
  }

  autoSave(id: string, payload, discharge: boolean = false): Observable<any> {
    return discharge ? this.http.put(`${this.url}/${id}/discharge`, payload) : this.http.put(`${this.url}/${id}`, payload);
  }

  getRatings(): Observable<any> {
    return this.http.get(`${this.url}/rating`);
  }

  getStatuses(): Observable<StatusAdmission[]> {
    return this.http.get<StatusAdmission[]>(`${this.url}/status`);
  }

  getTypes(): Observable<AdmissionTypes[]> {
    return this.http.get<AdmissionTypes[]>(`${this.url}/types`);
  }

  getRelationships(): Observable<any> {
    return this.http.get(`${this.url}/relationships`);
  }

  getReligions(): Observable<any> {
    return this.http.get(`${this.url}/religion`);
  }

  getAdmissionDetails(id: string, payload?: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http
        .get(`${this.url}/${id}/details`, {
          params: removeEmptyObjectValues(payload),
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              listAdmissionPatient: success.body,
              totalAdmissionPatient: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  setStarted(id: string, dataSend): Observable<any> {
    return this.http.post(`${this.url}/${id}/status/started`, { ...dataSend });
  }

  setStartDate(id: string, startDate: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/start-date`, { startDate });
  }

  updatePortalSOCDateCell(id: string, portalSOCDate: string) {
    return this.http.post(`${this.url}/${id}/portal-start-date`, { portalSOCDate });
  }

  setSupervisor(id: string, supervisorId: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/supervisor/${supervisorId}`, {});
  }

  setPatientSigner(id: string, relationship: number, reason: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/patient-signer`, { relationship, reason });
  }

  setPatientSignerWithoutUser(token: string, relationship: number, reason: string): Observable<any> {
    return this.http.post(`${this.url}/patient/admission/${token}/patient-signer`, { relationship, reason });
  }

  signDocument(id: string, documentKey, signs, discharge: boolean = false): Observable<any> {
    delete signs.supervisorSignerName;
    return discharge
      ? this.http.post(`${this.url}/${id}/sign-document/discharge`, { documentKey, signs })
      : this.http.post(`${this.url}/${id}/sign-document`, { documentKey, signs });
  }

  signDocumentWithoutLogin(token: string, documentKey, signs): Observable<any> {
    delete signs.supervisorSignerName;
    return this.http.post(`${this.url}/patient/admission/${token}/sign-document`, { documentKey, signs });
  }

  changeAdmissionSignDate(id: string, date): Observable<any> {
    return this.http.post(`${this.url}/${id}/sign-at`, { id, signAt: date });
  }

  setSigned(id: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/signed`, { id });
  }

  setSignedWithoutLogin(token: string, isEmployee: boolean = false): Observable<any> {
    return this.http.post(`${this.url}/patient/admission/${token}/signed${isEmployee ? '/employee' : ''}`, {});
  }

  download(id: string, type: 'pdf' | 'zip'): Observable<any> {
    return this.http.get(`${this.url}/${id}/download/${type}`);
  }

  setAdmissionsNotesById(id: string, text: string, reason: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/admissions/${id}/notes`, { text, reason: reason ? +reason : null });
  }

  getAdmissionsNotesById(id: string, filters?: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http
        .get(`${this.apiUrl}/admissions/${id}/notes`, { params: filters, observe: 'response' })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              totalNotes: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  updateAdmissionsNoteById(id: string, uuid: string, text: string, reason: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/admissions/${id}/notes/${uuid}`, { text, reason: +reason || null });
  }

  deleteAdmissionsNotesById(id: string, uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/admissions/${id}/notes/${uuid}`);
  }

  sync(): Observable<any> {
    return this.http.get(`${this.url}/sync`);
  }

  getSyncStatus(): Observable<any> {
    return this.http.get(`${this.url}/sync/status`);
  }

  saveScheduleDaysVisit(payload: Payload): Observable<any> {
    const data = payload.isEmpty
      ? null
      : {
          ...payload,
        };
    return this.http.post(`${this.url}/${payload.id}/schedule`, data);
  }

  createAdmissionFromAttachment(payload: Payload): Observable<any> {
    const data = payload.isEmpty
      ? null
      : {
          ...payload,
        };
    return this.http.post(`${this.url}/${payload.id}/from-attachment`, data);
  }

  generateAdmission(id, type): Observable<any> {
    const data = {
      id,
      type,
    };
    return this.http.post(`${this.url}/${id}/generate/${type}`, data);
  }

  generatePOC(id, type, payload): Observable<any> {
    return this.http.post(`${this.url}/${id}/generate/${type}`, payload);
  }

  deleteAdmission(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  getDocumentAdmissionTypes(id: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http
        .get(`${this.url}/${id}/document-list`)
        .pipe(take(1))
        .subscribe({
          next: (data: any) => {
            observer.next({
              listAdmissionDocuments: data,
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  approve(id: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/approved`, id);
  }

  decline(id: string, comment: string, withSigns: boolean): Observable<any> {
    const data = { id, comment, withSigns };
    return this.http.post(`${this.url}/${id}/decline`, data);
  }

  updateCarePlan(id: string, patientUUId: string, payerId: string, updateTimesheet: boolean): Observable<any> {
    const data = { id: patientUUId, payerId, updateTimesheet };
    return this.http.post(`${this.url}/${id}/payer`, data);
  }

  changeAdmissionOffice(id: string, departmentId: number): Observable<any> {
    return this.http.post(`${this.url}/${id}/department`, { departmentId });
  }

  setAssignedUser(uuid: string[], assignIds: string[], assignAllDocuments: number): Observable<any> {
    const data: any = {
      assignIds,
      ids: uuid,
    };
    if (assignAllDocuments) {
      data.assignAllDocuments = 1;
    }
    return this.http.post(`${this.apiUrl}/v2/admissions/assign`, data);
  }

  getUserList(search: string, params: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2/admissions/assign/users`, {
      params: {
        search,
        forFilter: params,
      },
    });
  }

  deleteAssignedUser(admissionId: string, userId: string, assignAllDocuments: number): Observable<void> {
    const params: any = {
      assignAllDocuments,
    };
    return this.http.delete<void>(`${this.apiUrl}/v2/admissions/${admissionId}/assign/${userId}`, {
      params: params?.assignAllDocuments ? params : null,
    });
  }
}
