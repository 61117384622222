export enum FiltersKeyEnum {
  Employees = 'employees',
  EmployeeAttachments = 'employeeAttachments',
  EmployeeNotes = 'employeeNotes',
  EmployeeAttachmentsApp = 'employeeAttachmentsApp',
  Admissions = 'admissions',
  Patient = 'patients',
  PatientAdmissions = 'patientAdmissions',
  PatientNotes = 'patientNotes',
  PatientAttachments = 'patientAttachments',
  PatientIncidents = 'patientIncidents',
  PatientRedFlags = 'patientRedFlags',
  PatientRedFlagsReports = 'patientRedFlagsReports',
  PatientTimesheets = 'patientTimesheets',
  Incidents = 'incidents',
  NewIncidents = 'newIncidents',
  AllIncidents = 'allIncidents',
  PatientsIncidentReport = 'patientsIncidentReport',
  TimeSheets = 'timesheets',
  RedFlags = 'redFlags',
  RedFlagAttachments = 'redFlagAttachments',
  IncompleteReports = 'incompleteReports',
  EmployeeDocuments = 'employeeDocuments',
  IncidentAttachments = 'incidentAttachments',
  IncidentNotes = 'incidentNotes',
  AdmissionDetails = 'admissionDetails',
  AdmissionAttachments = 'admissionAttachments',
  AdmissionAttachmentsApp = 'admissionAttachmentsApp',
  Hospitals = 'hospitals',
  Doctors = 'doctors',
  Users = 'users',
  Lessons = 'lessons',
  LessonsCategories = 'lessonCategories',
  LessonPlans = 'lessonPlans',
  LessonPlanDetails = 'lessonPlanDetails',
  UserEmployees = 'userEmployees',
  Roles = 'roles',
  MedicalDashboard = 'medicalDashboard',
  MedicalDocumentsHistory = 'medicalDocumentsHistory',
  MedicalAttachments = 'medicalAttachments',
  MedicalAttachmentsApp = 'medicalAttachmentsApp',
  MedicalNotes = 'medicalNotes',
}
