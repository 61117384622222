import { Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';
import { PopupFactoryInterface, PopupFactoryService } from './popups/popup-factory.service';
import { PopupBaseComponent } from './popups/popup-base/popup-base.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Title } from '@angular/platform-browser';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { VersionCheckerService } from './version-checker.service';
import { ConnectionService } from './shared/services/connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @ViewChild('popupPortal', { read: ViewContainerRef, static: true })
  popupPortal: ViewContainerRef;

  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);
  networkStatus$: Observable<boolean> = this.connectionService.networkStatus$;
  title: string = 'acc';
  isSuppressScrollY: boolean;
  config: PerfectScrollbarConfigInterface = {};
  showLoader: boolean = true;

  constructor(
    private popupFactory: PopupFactoryService,
    private titleService: Title,
    private router: Router,
    private versionChecker: VersionCheckerService,
    private connectionService: ConnectionService,
  ) {
    this.setTitle();
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closePopupHandler(): void {
    this.popupPortal.clear();
    if (document?.body?.classList) {
      document.body.classList.remove('fixed');
    }
    this.isSuppressScrollY = false;
  }

  private init(): void {
    this.popupFactory.scrollSuppress.subscribe((value: boolean) => (this.isSuppressScrollY = value));
    this.popupFactory.popup$.subscribe(popup => {
      this.createPopupHandler(popup);
    });
    this.popupFactory.closePopup$.subscribe(() => {
      this.closePopupHandler();
    });

    const unsub: Subject<void> = new Subject();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
        tap(ev => {
          if (ev instanceof NavigationStart) {
            this.showLoader = true;
          }
          if (ev instanceof NavigationEnd) {
            this.showLoader = false;
            unsub.next();
          }
        }),
        takeUntil(unsub),
      )
      .subscribe();

    if (environment.production) {
      this.versionChecker.startChecking();
    }
    this.connectionService.init();
  }

  private createPopupHandler(value: PopupFactoryInterface): void {
    this.popupPortal.clear();
    const popupInstance = this.popupPortal.createComponent(PopupBaseComponent).instance;
    popupInstance.title = value.title;
    popupInstance.hideIcon = value.hideIcon;
    popupInstance.popupComponent = value.popupComponent;
    popupInstance.popupData = value.popupData;
    popupInstance.subscribers = value.subscribers;
    popupInstance.popupBodyClass = value.popupBodyClass;
    popupInstance.hideCloseBtn = value.hideCloseBtn;
    popupInstance.preventBgClick = value.preventBgClick;
    popupInstance.isHeightFull = value.isHeightFull;
    popupInstance.isReadLatterConfirm = value.isReadLatterConfirm;
    document.body.classList.add('fixed');
    this.isSuppressScrollY = true;
    popupInstance.close.pipe(take(1)).subscribe(data => {
      this.popupPortal.clear();
      if (document?.body?.classList) {
        document.body.classList.remove('fixed');
      }
      this.isSuppressScrollY = false;
      this.popupFactory.popupOutput$.next(data);
    });
  }

  private setTitle(): void {
    this.titleService.setTitle(environment.title);
  }
}
