import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { VersionCheckerService } from './version-checker.service';
import { environment } from '../environments/environment';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private sentryErrorHandler: Sentry.SentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
    logErrors: !environment.production,
  });

  constructor(private injector: Injector) {}

  public handleError(error: Error): void {
    const chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      const versionCheckerService: VersionCheckerService = this.injector.get(VersionCheckerService);
      versionCheckerService.showPopup();
    }

    this.sentryErrorHandler.handleError(error);
  }
}
