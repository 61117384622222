import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: environment.sentryDns,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: environment.tracesSampleRate,
});

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line no-console
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
