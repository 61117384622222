export interface AdmissionAttachment {
  HHAeXchange: boolean;
  HHAeXchangeAt: any;
  comment: string;
  ext: string;
  id: string;
  path: string;
  title: string;
  uploaded: string;
  file?: any;
}

export interface AdmissionAttachmentDefaultState {
  admissionId: string;
  page: number;
  perPage: number;
  admissionAttachmentsList: AdmissionAttachment[];
  total: number;
  isLoading: boolean;
}
