import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import {
  ClearNotes,
  CreatePatientNote,
  DeletePatientNote,
  DeleteUserToAssignPatient,
  EditPatientNote,
  EnableDatatableLoading,
  GetCoordinators,
  GetPatientById,
  GetPatientList,
  GetPatientListFilter,
  GetPatientNoteReason,
  GetPatientNoteReasonsFilters,
  GetPatientNotes,
  GetPatientPositions,
  GetPatientStatuses,
  GetUserListToAssignFilterPatient,
  GetUserListToAssignPatient,
  ResetPatientListErrors,
  SetAssignUserToPatient,
  SetNotesPage,
  SetPatient,
  SetStatusPatientHHa,
} from './patients.actions';
import { PatientsService } from '../../../services/patients.service';
import { IncidentsService } from '../../../services/incidents.service';
import { StaticDataService } from '../../../../static-data.service';
import { Injectable } from '@angular/core';
import { PatientStatuses } from '../../../patients/patients.component';
import { Observable, throwError, timer } from 'rxjs';
import { UserToAssign } from '../../../../shared/models/assigned-model';
import { FiltersKeyEnum } from '../../../../shared/enums/filtersKey.enum';
import { removeEmptyObjectValues } from '../../../../shared/helpers/other';
import { IdNameObject } from '../../../../shared/models';

export interface PatientDto {
  coordinator?: { id: number; name: string };
  dob: string;
  hasAdmissions: boolean;
  id: string;
  medicaidNumber: string;
  name: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    fullName?: string;
  };
  patientId: string;
}

export interface PatientFilter {
  id: string;
  fullName: string;
}

export interface PatientsStateModel {
  list: PatientDto[];
  total: number;
  startDate: string;
  coordinatorsList: any[];
  patientStatuses: number[];
  patientStatusesList: PatientStatuses[];
  coordinators: any[];
  newPatientsErrors?: any;
  static?: any;
  reason?: Reason[];
  notes?: Note[];
  notePage: number;
  notesTotal: number;
  reasonFilter: Reason[];
  patientsFilter: PatientFilter[];
  userList: UserToAssign[];
  userListFilters: UserToAssign[];
  isLoading: boolean;
}

export interface Reason {
  active: string;
  id: number;
  title: string;
}

export interface Note {
  author: string;
  id: string;
  reason: Reason;
  text: string;
  updatedAt: string;
  resourceId?: string;
  type?: 'incident' | 'admission' | 'patient' | 'discharge';
}

export const defaultState: PatientsStateModel = {
  list: [],
  total: 0,
  startDate: '',
  coordinatorsList: [],
  patientStatusesList: [],
  patientStatuses: [],
  coordinators: [],
  newPatientsErrors: null,
  static: null,
  reason: [],
  notes: [],
  notePage: 1,
  notesTotal: 0,
  reasonFilter: [],
  patientsFilter: [],
  userList: [],
  userListFilters: [],
  isLoading: true,
};

@State({
  name: 'patients',
  defaults: defaultState,
})
@Injectable()
export class PatientsState {
  constructor(
    private service: PatientsService,
    private incidents: IncidentsService,
    private staticService: StaticDataService,
    private store: Store,
  ) {}

  @Selector()
  static isLoading(state): boolean {
    return state.isLoading;
  }

  @Selector()
  static reason(state): any[] {
    return state.reason;
  }

  @Selector()
  static reasonFilter(state): any {
    return state.reasonFilter || {};
  }

  @Selector()
  static notes(state): any {
    return state.notes;
  }

  @Selector()
  static notesTotal(state): number {
    return state.notesTotal;
  }

  @Selector()
  static notePage(state): number {
    return state.notePage;
  }

  @Selector()
  static list(state): any {
    return state.list;
  }

  @Selector()
  static patientsFilter(state): Array<Observable<PatientFilter>> {
    return state.patientsFilter;
  }

  @Selector()
  static usersToAssign(state): Array<Observable<PatientFilter>> {
    return state.userList;
  }

  @Selector()
  static usersToAssignFilter(state): Array<Observable<PatientFilter>> {
    return state.userListFilters;
  }

  @Selector()
  static total(state): number {
    return state.total;
  }

  @Selector()
  static page(state): number {
    return state.page;
  }

  @Selector()
  static perPage(state): number {
    return state.perPage;
  }

  @Selector()
  static statuses(state): any {
    return state.statusList;
  }

  @Selector()
  static coordinators(state): any {
    return state.coordinatorsList;
  }

  @Selector()
  static patientStatuses(state): any {
    return state.patientStatusesList;
  }

  @Selector()
  static newPatientsErrors(state): any {
    return state.newPatientsErrors || {};
  }

  getFilters(filtersValue: any): any {
    const { search, order, dir, patientStatuses, portalPatientStatuses, assigned, coordinators, page, perPage } = filtersValue;
    return removeEmptyObjectValues({
      page,
      perPage,
      order,
      dir,
      search,
      'coordinatorIds[]': coordinators ? coordinators : [],
      'patientStatuses[]': patientStatuses ? patientStatuses : [],
      'portalPatientStatuses[]': portalPatientStatuses ? portalPatientStatuses : [],
      'assigned[]': assigned ? assigned : [],
    });
  }

  @Action(GetPatientList)
  getList(ctx: StateContext<PatientsStateModel>): Observable<any> {
    ctx.patchState({
      isLoading: true,
    });
    const filtersValue = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.Patient].model);
    return this.service
      .getAll({
        ...this.getFilters(filtersValue),
      })
      .pipe(
        tap(({ data, total }: any) => {
          ctx.patchState({
            list: data.map((item: any) => ({ ...item })),
            total,
          });
        }),
        finalize(() => {
          this.disableDataTableLoading(ctx);
        }),
      );
  }

  @Action(GetPatientListFilter)
  getListFilter(ctx: StateContext<PatientsStateModel>, { filterData }: GetPatientListFilter): Observable<PatientFilter[]> {
    return this.service.getPatientsFilter({ ...filterData }).pipe(
      tap((value: PatientFilter[]) => {
        ctx.patchState({
          patientsFilter: value,
        });
      }),
    );
  }

  @Action(GetPatientById)
  getPatientById(ctx: StateContext<PatientsStateModel>, { id }: GetPatientById): Observable<any> {
    return this.service.getById(id);
  }

  @Action(SetPatient)
  setPatient(ctx: StateContext<PatientsStateModel>, { patient }): Observable<any> {
    const list: PatientDto[] = ctx.getState().list;
    list.push(patient);
    ctx.patchState({
      list,
    });
    return this.service.setPatients(patient).pipe(
      tap(() => {
        list.push(patient);
        ctx.patchState({
          list,
        });
      }),
      catchError(err => {
        ctx.patchState({
          newPatientsErrors: err.error.violations,
        });
        return throwError(err);
      }),
    );
  }

  @Action(ResetPatientListErrors)
  ResetPatientListErrors(ctx: StateContext<any>): void {
    ctx.patchState({
      newPatientsErrors: null,
    });
  }

  @Action(GetCoordinators)
  getCoordinators(ctx: StateContext<PatientsStateModel>, { search }: GetCoordinators): Observable<IdNameObject[]> {
    return this.incidents.getCoordinators(search).pipe(
      tap((res: IdNameObject[]) => {
        ctx.patchState({
          coordinatorsList: res,
        });
      }),
    );
  }

  @Action(GetPatientStatuses)
  getPatientStatuses(ctx: StateContext<PatientsStateModel>): Observable<PatientStatuses[]> {
    return this.incidents.getPatientStatuses().pipe(
      tap((res: PatientStatuses[]): void => {
        ctx.patchState({
          patientStatusesList: res,
        });
      }),
    );
  }

  @Action(GetPatientPositions)
  getPatientPositions(ctx): Observable<any[]> {
    return this.staticService.getPositions().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          static: data,
        });
      }),
    );
  }

  @Action(GetPatientNoteReason)
  getPatientNoteReason(ctx: StateContext<PatientsStateModel>, { patientId }: GetPatientNoteReason): Observable<any> {
    return this.staticService.getPatientNoteReason(patientId).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reason: res,
        });
      }),
    );
  }

  @Action(CreatePatientNote)
  createPatientNote(ctx: StateContext<PatientsStateModel>, { payload }: CreatePatientNote): Observable<any> {
    return this.staticService.createPatientNote(payload);
  }

  @Action(EditPatientNote)
  editPatientNote(ctx: StateContext<PatientsStateModel>, { payload }: EditPatientNote): Observable<any> {
    return this.staticService.editPatientNote(payload);
  }

  @Action(GetPatientNotes)
  getPatientNotes(ctx: StateContext<PatientsStateModel>, { patientId, fromTable }: GetPatientNotes): Observable<any> {
    ctx.patchState({
      isLoading: true,
    });
    const defaultFilters = {
      search: '',
      authors: [],
      order: '',
      dir: '',
      page: 1,
      perPage: 30,
      notSavePerPage: true,
    };

    const filterValue = this.store.selectSnapshot(({ filters }) => filters?.[FiltersKeyEnum.PatientNotes]?.model) || defaultFilters;
    const state: PatientsStateModel = ctx.getState();
    const obj = {
      page: filterValue.page,
      perPage: fromTable ? filterValue.perPage || 10 : 30,
      search: filterValue.search || '',
      'reasons[]': filterValue && filterValue.reasons ? filterValue.reasons : [],
      'authors[]': filterValue && filterValue.authors ? filterValue.authors.map(item => item.split('/')[0]) : [],
      notSavePerPage: filterValue.notSavePerPage ? 1 : 0,
      dir: filterValue.dir,
      order: filterValue.order,
    };

    return this.staticService.getPatientNotes(patientId, obj).pipe(
      tap(({ data, total }) => {
        if (state.notePage > 1 && !fromTable) {
          ctx.patchState({
            notes: [...state.notes, ...data],
            notesTotal: total,
          });
        } else {
          ctx.patchState({
            notes: data,
            notesTotal: total,
          });
        }
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(DeletePatientNote)
  deletePatientNote(ctx: StateContext<PatientsStateModel>, { payload }: DeletePatientNote): Observable<void> {
    return this.staticService.deletePatientNote(payload);
  }

  @Action(ClearNotes)
  clearNotes(ctx: StateContext<PatientsStateModel>): PatientsStateModel {
    return ctx.patchState({
      notes: [],
    });
  }

  @Action(SetNotesPage)
  setNotesPage(ctx: StateContext<PatientsStateModel>, { page }: SetNotesPage): any {
    return ctx.patchState({
      notePage: page,
    });
  }

  @Action(GetPatientNoteReasonsFilters)
  getPatientNoteReasonsFilter(ctx: StateContext<any>, { patientId }: any): Observable<any> {
    return this.staticService.getPatientNoteReasonFilter(patientId).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reasonFilter: res,
        });
      }),
    );
  }

  @Action(SetStatusPatientHHa)
  setStatusPatientHHa(ctx: StateContext<any>, { patientId, status }: any): Observable<any> {
    return this.staticService.setStatusPatientHHa(patientId, status);
  }

  @Action(SetAssignUserToPatient)
  setAssignUserToPatient(ctx: StateContext<any>, { uid, assignIds }: SetAssignUserToPatient): Observable<any> {
    return this.service.setAssignUserToPatient(uid, assignIds);
  }

  @Action(DeleteUserToAssignPatient)
  deleteAssignUserToPatient(ctx: StateContext<any>, { patientId, userId }: DeleteUserToAssignPatient): Observable<any> {
    return this.service.deleteAssignedUser(patientId, userId);
  }

  @Action(GetUserListToAssignPatient)
  setUserListToAssignPatient(ctx: StateContext<any>, { search }: GetUserListToAssignPatient): Observable<any> {
    return this.service.getUserListToAssign(search, 0).pipe(
      tap(users => {
        ctx.patchState({
          userList: users,
        });
      }),
    );
  }

  @Action(GetUserListToAssignFilterPatient)
  setUserListToAssignFilterPatient(ctx: StateContext<any>, { search }: GetUserListToAssignFilterPatient): Observable<any> {
    return this.service.getUserListToAssign(search, 1).pipe(
      tap(users => {
        ctx.patchState({
          userListFilters: users,
        });
      }),
    );
  }

  @Action(EnableDatatableLoading)
  enableDatatableLoading(ctx: StateContext<any>): void {
    ctx.patchState({
      isLoading: true,
    });
  }

  private disableDataTableLoading(ctx: StateContext<any>): void {
    timer(1000)
      .pipe(take(1))
      .subscribe(() => {
        ctx.patchState({
          isLoading: false,
        });
      });
  }
}
