function removeEmptyObjectValues(obj = {}): any {
  Object.keys(obj).forEach((k: string) => {
    if (obj[k] === '' || obj[k] === undefined) {
      delete obj[k];
    }
  });
  return obj;
}

function rmEmptyObjectValuesWithEmptyArray(obj = {}): any {
  const newObj = {};
  Object.keys(obj).forEach((k: string) => {
    if (obj[k] !== '' && obj[k] !== undefined && obj[k] !== null && !(Array.isArray(obj[k]) && obj[k].length === 0)) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

function resizeWindowTrigger(): void {
  window.dispatchEvent(new Event('resize'));
}

function getDataFromLocalStorage(key: string, isParse: boolean = true): any {
  try {
    return isParse ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
  } catch (err: any) {
    return null;
  }
}

function concatenateStrSeparatedByCommas(arr: string[]): string {
  if (arr.some((elem: string): boolean => typeof elem === 'string' || typeof elem === 'number')) {
    return arr.filter((elem: string) => (typeof elem === 'string' || typeof elem === 'number') && elem).join(', ');
  } else {
    return '';
  }
}

function triggerInputElementBlur(): void {
  const inputElement: HTMLInputElement = document.activeElement as HTMLInputElement;
  if (inputElement && inputElement.tagName === 'INPUT') {
    inputElement.blur();
  }
}

function capitalizeAndTrimWords(value: string): string {
  if (value.split(' ').length > 1) {
    return value
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

function rmDuplicatesElementsByKey(arr: any[], key: string): any[] {
  const uniqueKeys: Set<any> = new Set();
  return arr.filter((item): boolean => {
    if (!uniqueKeys.has(item[key])) {
      uniqueKeys.add(item[key]);
      return true;
    }
    return false;
  });
}

function getParsePhone(phone: string, valueLength: number = 12): string {
  return phone
    .replace(/-/g, '')
    .replace(/\s+/g, '')
    .replace(/_/g, '')
    .replace(/\(/g, '')
    .replace(/\s/g, '')
    .replace(/\)/g, '')
    .substr(0, valueLength);
}

function arraysHaveSameElements(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1: any[] = arr1.slice().sort();
  const sortedArr2: any[] = arr2.slice().sort();

  for (let i: number = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

function cloneDeep(data) {
  if (!data) {
    return null;
  }
  return JSON.parse(JSON.stringify(data));
}

function getFromStorage(key: string) {
  return JSON.parse(sessionStorage.getItem(key)) || [];
}

function rmFromStorage(key: string) {
  sessionStorage.removeItem(key);
}

const emailValidatePattern: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

export {
  removeEmptyObjectValues,
  rmEmptyObjectValuesWithEmptyArray,
  resizeWindowTrigger,
  getDataFromLocalStorage,
  concatenateStrSeparatedByCommas,
  triggerInputElementBlur,
  capitalizeAndTrimWords,
  rmDuplicatesElementsByKey,
  getParsePhone,
  arraysHaveSameElements,
  cloneDeep,
  getFromStorage,
  rmFromStorage,
  emailValidatePattern,
};
