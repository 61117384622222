import { environment } from '../../../environments/environment';

function openLink(link): void {
  setTimeout((): void => {
    if (environment.openFileInNewTab) {
      window.open(link, '_blank');
    }
  }, 300);
}

export { openLink };
