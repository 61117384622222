export interface Documents {
  id: number;
  draftPages: number[] | null;
  key: string;
  name: string;
  totalPages: number;
  pages?: any;
}

interface DocumentName {
  firstName: string;
  lastName: string;
}

interface DocumentStatus {
  id: number;
  title: string;
  color: string;
  isSpecial: boolean;
}

export interface DocumentOfList {
  id: number;
  name: DocumentName;
  status?: DocumentStatus;
  documentNames?: string[];
  documents?: Documents[];
  createdAt: string;
  updatedAt?: string;
  sendForSignedAt?: string;
  signedAt?: string;
  completedAt?: string;
  hhaexchangeUploadAt?: string;
}
